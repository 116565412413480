import { Link } from "gatsby"
import React from "react"
import Layout from '../components/layout';
import "../styles/scss/common/oops-page.scss"

const NotFoundPage = () => {
	const seoData = {
		'title' : 'Page not found', 
		'description' : '404 page', 
		'canonicalUrl' : '/404/', 
		'robots' : 'noindex,follow'
		};
	
	return(
  <Layout page={seoData} >
    <div className="container oops-page">
    <img src={'/images/404.svg'} alt="404 Error" />
    <p>OOPS ! Looks like the page that you&#39;re looking for is no longer available !!</p>
    <Link to="/">GO HOME</Link>
    </div>
  </Layout>
)
}

export default NotFoundPage
